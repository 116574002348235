<template>
  <div class="container">
    <el-breadcrumb separator-class="el-icon-arrow-right" style="	height: 40px;">
      <el-breadcrumb-item>{{
        fun.getLanguageText($store.state.language, "设备管理")
      }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{
        fun.getLanguageText($store.state.language, "设备列表")
      }}</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="top">
      <el-input
        v-model="searchData.value"
        :placeholder="
          fun.getLanguageText($store.state.language, '请输入关键词进行搜索')
        "
      ></el-input>
      <el-button icon="el-icon-search" type="primary" @click="search">
        {{ fun.getLanguageText($store.state.language, "搜索") }}
      </el-button>
      <el-select
        v-model="searchData.type1"
        :placeholder="
          fun.getLanguageText($store.state.language, '请选择设备类型')
        "
        @change="changeSearchData($event, 1)"
      >
        <el-option
          v-for="item in DeviceTypeList1"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="searchData.type2"
        :placeholder="
          fun.getLanguageText($store.state.language, '请选择设备名称')
        "
        @change="changeSearchData($event, 2)"
      >
        <el-option
          v-for="item in DeviceTypeList2"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="searchData.type3"
        :placeholder="
          fun.getLanguageText($store.state.language, '请选择设备型号')
        "
        @change="changeSearchData($event, 3)"
      >
        <el-option
          v-for="item in DeviceTypeList3"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <el-select
        v-model="searchData.type4"
        disabled
        v-if="false"
        :placeholder="
          fun.getLanguageText($store.state.language, '请选择设备状态')
        "
      >
        <el-option label="正常" value="1"></el-option>
        <el-option label="关机" value="2"></el-option>
        <el-option label="无信号" value="3"></el-option>
      </el-select>
      <el-button
        v-if="$store.state.roleId < 4"
        class="add-username"
        icon="el-icon-plus"
        type="primary"
        @click="add"
        >{{ fun.getLanguageText($store.state.language, "添加设备") }}
      </el-button>
    </div>
    <el-table
      :data="tableData"
      :max-height="$store.state.clientHeight"
      stripe
      style="width: 100%"
    >
      <el-table-column
        :label="fun.getLanguageText($store.state.language, '序号')"
        prop="index"
        width="120"
      >
      </el-table-column>
      <el-table-column
        :label="fun.getLanguageText($store.state.language, '设备种类')"
        prop="deviceType"
      >
      </el-table-column>
      <el-table-column
        :label="fun.getLanguageText($store.state.language, '设备名称')"
        prop="deviceName"
      >
      </el-table-column>
      <el-table-column
        :label="fun.getLanguageText($store.state.language, '设备序列号')"
        prop="sn"
      >
      </el-table-column>
      <el-table-column
        :label="fun.getLanguageText($store.state.language, '设备型号')"
        prop="deviceModel"
      >
      </el-table-column>
      <el-table-column
        :label="fun.getLanguageText($store.state.language, '客户公司')"
        prop="customer"
      >
      </el-table-column>
      <el-table-column
        :label="fun.getLanguageText($store.state.language, '终端公司名称')"
        prop="client"
      >
      </el-table-column>
      <el-table-column
        :label="fun.getLanguageText($store.state.language, '状态')"
        prop="stat"
      >
        <template slot-scope="scope">
          <span v-if="scope.row.stat === '在线'" style="color: #13ce66">{{
            fun.getLanguageText($store.state.language, scope.row.stat)
          }}</span>
          <span v-else style="color: red">{{
            fun.getLanguageText($store.state.language, scope.row.stat)
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="fun.getLanguageText($store.state.language, '国家/城市')"
        :prop="$store.state.language == 1 ? 'place' : 'place_en'"
      >
      </el-table-column>
      <el-table-column
        fixed="right"
        :label="fun.getLanguageText($store.state.language, '操作')"
        :width="$store.state.language == 2 ? '280' : '290'"
      >
        <template slot-scope="scope">
          <el-button size="small" type="text" @click="set(scope)"  :style="$store.state.language == 1 ? '' : 'margin-left: 10px;'">
            {{ fun.getLanguageText($store.state.language, "参数设定") }}
          </el-button>
          <el-button
            :disabled="scope.row.stat !== '在线'"
            size="small"
            type="text"
            @click="goState(scope)"
          >
            {{ fun.getLanguageText($store.state.language, "设备状态") }}
          </el-button>
          <el-button
            :disabled="$store.state.roleId > 3"
            size="small"
            type="text"
            @click="deleteDevice(scope)"
          >
            {{ fun.getLanguageText($store.state.language, "删除") }}
          </el-button>
          <el-button
            size="small"
            type="text"
            @click="templateSave(scope.row.sn)"
          >
            {{ fun.getLanguageText($store.state.language, "保存模板") }}
          </el-button>
          <el-button
            size="small"
            type="text"
            @click="selectTemplate(scope.row.sn)"
          >
            {{ fun.getLanguageText($store.state.language, "选择模板") }}
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :current-page="pageData.currentPage"
      :page-size="pageData.pageSize"
      :page-sizes="pageData.pageSizes"
      :total="tableData.length"
      background
      class="footer"
      layout="sizes, prev, pager, next, jumper, total"
      @size-change="sizeChange"
      @current-change="currentChange"
    >
    </el-pagination>

    <!-- 弹出框选择下拉框 -->
    <el-dialog
      :visible.sync="dialogFormVisible"
      :title="fun.getLanguageText($store.state.language, '选择模板')"
      width="420px"
      @close="diaClose"
    >
      <el-select
        v-model="template_id"
        :loading="loading"
        :remote-method="test"
        filterable
        :placeholder="fun.getLanguageText($store.state.language, '请选择模板')"
        remote
        reserve-keyword
        style="width: 340px"
      >
        <el-option
          v-for="item in template_list"
          :key="item.id"
          :label="item.templateName"
          :value="item.id"
        >
        </el-option>
      </el-select>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">{{
          fun.getLanguageText($store.state.language, "取消")
        }}</el-button>
        <el-button
          :disabled="template_id === ''"
          type="primary"
          @click="applyTemplate"
          >{{ fun.getLanguageText($store.state.language, "确定") }}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import utl from "@/common/utils.js";

export default {
  data() {
    return {
      router: false,
      input: "",
      user_type: "",
      tableData: [],
      searchData: {
        value: "", // 查询内容
        type1: "", // 类型ID
        type2: "", // 名称id
        type3: "", // 型号id
        type4: "",
      },
      DeviceTypeList1: [],
      DeviceType1: 0,
      DeviceTypeList2: [],
      DeviceType2: 0,
      DeviceTypeList3: [],
      DeviceType3: 0,
      hA: 0,
      pageData: {
        total: 0, // 总条数
        currentPage: 1, // 当前页数
        totalPage: 0, // 总页数
        pageSize: 20, //
        pageSizes: [10, 20, 30, 50],
      },
      dialogFormVisible: false,
      // 模板列表
      template_list: [],
      // 当前选中模板
      template_id: "",
      // 当前选中sn
      l_sn: "",
      // 搜索延时
      time_search: 0,
      loading: false,
    };
  },

  created() {
    console.log(this.$store.state.roleId);
    this.getDeviceList();
    this.getDeviceTypeList(1);
  },
  methods: {
    // 模板覆盖
    async applyTemplate() {
      let ret = await this.$http.post("template/apply", {
        id: this.template_id,
        sn: this.l_sn,
      });
      console.log(ret);
      if (ret.data.code === 2000) {
        this.template_list = ret.data.data.info;
        this.$message({
          type: "success",
          message: this.fun.getLanguageText(
            this.$store.state.language,
            "模板设置成功"
          ),
        });
        this.dialogFormVisible = false;
        return;
      }
      this.$message.error(
        this.fun.getLanguageText(this.$store.state.language, ret.data.msg)
      );
    },
    test: utl.debounce(function(e) {
      console.log(e);
      this.getTemplateList(e);
    }, 300),
    // 弹出框被隐藏
    diaClose() {
      this.template_list = [];
      this.template_id = "";
    },
    // 触发选择模板
    selectTemplate(sn) {
      this.getTemplateList();
      this.dialogFormVisible = true;
      this.l_sn = sn;
    },
    // 模板保存
    templateSave(sn) {
      this.$prompt(
        this.fun.getLanguageText(this.$store.state.language, "请输入模板名称"),
        this.fun.getLanguageText(this.$store.state.language, "提示"),
        {
          confirmButtonText: this.fun.getLanguageText(
            this.$store.state.language,
            "确定"
          ),
          cancelButtonText: this.fun.getLanguageText(
            this.$store.state.language,
            "取消"
          ),
        }
      )
        .then(async ({ value }) => {
          let ret = await this.$http.post("template/save", {
            templateName: value,
            sn,
          });
          console.log(ret);
          if (ret.data.code === 2000) {
            this.$message({
              type: "success",
              message: this.fun.getLanguageText(
                this.$store.state.language,
                "保存模板成功"
              ),
            });
          } else {
            this.$message.error(
              this.fun.getLanguageText(this.$store.state.language, ret.data.msg)
            );
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.fun.getLanguageText(
              this.$store.state.language,
              "取消操作"
            ),
          });
        });
    },
    // 读取模板列表
    async getTemplateList(search) {
      let ret = await this.$http.post("template/list", {
        search,
      });
      console.log(ret);
      if (ret.data.code === 2000) {
        this.template_list = ret.data.data.info;
      }
    },

    // 固件升级
    log() {
      this.$message({
        type: "info",
        message: this.fun.getLanguageText(
          this.$store.state.language,
          "暂不支持固件升级"
        ),
      });
    },
    // 跳入设备状态
    goState(e) {
      console.log(e.row.sn);
      this.$router.push({
        name: "Home/DeviceState",
        query: {
          sn: e.row.sn,
        },
      });
    },
    // 删除设备
    async deleteDevice(e) {
      // 二次确认
      let ret_msg;
      try {
        ret_msg = await this.$prompt(
          this.fun.getLanguageText(
            this.$store.state.language,
            "此操作将永久删除该设备, 是否继续?"
          ),
          this.fun.getLanguageText(this.$store.state.language, "提示"),
          {
            confirmButtonText: this.fun.getLanguageText(
              this.$store.state.language,
              "确定"
            ),
            cancelButtonText: this.fun.getLanguageText(
              this.$store.state.language,
              "取消"
            ),
            inputPlaceholder: this.fun.getLanguageText(
              this.$store.state.language,
              "请输入设备密码"
            ),
            type: "warning",
          }
        );
      } catch (err) {
        this.$message({
          type: "info",
          message: this.fun.getLanguageText(
            this.$store.state.language,
            "已取消删除"
          ),
        });
        return;
      }
      if (ret_msg.action !== "confirm") {
        this.$message({
          type: "info",
          message: this.fun.getLanguageText(
            this.$store.state.language,
            "已取消删除"
          ),
        });
        return;
      }
      if (!ret_msg.value) {
        this.$message.error(
          this.fun.getLanguageText(
            this.$store.state.language,
            "请输入设备密码执行删除操作"
          )
        );
        return;
      }

      let ret = await this.$http.post("device/delete", {
        id: e.row.id,
        password: ret_msg.value,
      });
      ret = ret.data;
      if (ret.code !== 2000) {
        this.$message.error(
          this.fun.getLanguageText(this.$store.state.language, ret.msg)
        );
        return;
      }
      this.$message({
        type: "success",
        message: this.fun.getLanguageText(
          this.$store.state.language,
          "删除成功!"
        ),
      });
      this.getDeviceList();
    },
    // 搜素
    search() {
      this.getDeviceList();
    },
    // 下拉选择框被改变
    changeSearchData(e, id) {
      console.log(e, id);
      if (id === 1) {
        this.DeviceType1 = e;
        this.searchData.type2 = "";
        this.searchData.type3 = "";
        this.DeviceTypeList2 = [];
        this.DeviceTypeList3 = [];
        this.getDeviceTypeList(2, e);
      }
      if (id === 2) {
        this.DeviceType2 = e;
        this.searchData.type3 = "";
        this.DeviceTypeList3 = [];
        this.getDeviceTypeList(3, e);
      }
      if (id === 3) {
        this.DeviceType3 = e;
      }
      console.log(this.searchData);
      this.getDeviceList();
    },
    // 获取设备类型列表
    getDeviceTypeList(type, pid) {
      // 1:类型 2:名称 3型号
      this.$http
        .post("device-type/list", {
          type,
          pid,
        })
        .then((res) => {
          if (res.data.code === 2000) {
            if (type == 1) {
              this.DeviceTypeList1 = res.data.data;
            }
            if (type == 2) {
              this.DeviceTypeList2 = res.data.data;
            }
            if (type == 3) {
              this.DeviceTypeList3 = res.data.data;
            }
          }
          console.log(res);
        });
    },
    // 获取设备列表
    getDeviceList() {
      this.$http
        .post("device/list", {
          search: this.searchData.value,
          deviceTypeId: this.searchData.type1,
          deviceNameId: this.searchData.type2,
          deviceModelId: this.searchData.type3,
          currentPage: this.pageData.currentPage,
          pageSize: this.pageData.pageSize,
        })
        .then((res) => {
          if (res.data.code != 2000) {
            return;
          }
          console.log(res);
          if (res.data.data.info) {
            res.data.data.info.map((item, index) => {
              item.stat = item.stat == 1 ? "在线" : "离线";
              item.index = index + 1;
            });
            this.tableData = res.data.data.info;
          } else {
            this.tableData = [];
          }
        });
    },
    // 参数设定
    set(e) {
      this.$router.push({
        name: "Home/Device/SetDevice",
        query: {
          sn: e.row.sn,
        },
      });
    },
    // 添加用户
    add() {
      this.$router.push("/Home/Device/AddDevice");
    },
    // 删除指定账号
    del(e) {
      this.$confirm(
        this.fun.getLanguageText(
          this.$store.state.language,
          "此操作将永久删除该文件, 是否继续?"
        ),
        this.fun.getLanguageText(this.$store.state.language, "提示"),
        {
          confirmButtonText: this.fun.getLanguageText(
            this.$store.state.language,
            "确定"
          ),
          cancelButtonText: this.fun.getLanguageText(
            this.$store.state.language,
            "取消"
          ),
          type: "warning",
        }
      )
        .then(() => {
          // 执行删除操作
          this.tableData.splice(e.$index, 1);
          this.$message({
            type: "success",
            message: this.fun.getLanguageText(
              this.$store.state.language,
              "删除成功!"
            ),
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.fun.getLanguageText(
              this.$store.state.language,
              "已取消删除"
            ),
          });
        });
    },
    // 改变页码
    currentChange(e) {
      this.pageData.currentPage = e;
      this.getDeviceList();
    },
    // 选择页码
    sizeChange(e) {
      this.pageData.pageSize = e;
      this.getDeviceList();
    },
    // 计算高度
    getListHeight() {
      let Html = document.getElementsByClassName("box-card");
      if (Html.length > 0) {
        let h = Html[0].clientHeight - 131 - 60;
        this.$store.commit("setClientHeight", Number(h) + 1);
        console.log(this.$store.state.clientHeight);
        this.$forceUpdate();
      }
    },
  },
  mounted() {
    this.getListHeight();
    window.onresize = () => {
      return (() => {
        this.getListHeight();
      })();
    };
  },
  destroyed() {
    window.onresize = null;
  },
};
</script>

<style lang="less" scoped>
.el-input {
  width: 200px;
  margin-right: 10px;
}

.el-select {
  width: 180px;
  margin-left: 20px;
}

.container {
  display: flex;
  flex-direction: column;
}

.top {
  // height: 32px;
  margin-bottom: 20px;
  position: relative;
}

.add-username {
  position: absolute;
  right: 0;
}

.footer {
  display: flex;
  padding: 0;
  margin-top: 14px;
  justify-content: flex-end;
}
</style>
